import ConfigTable from "../tables/components/ConfigTable";
import { columnsDataComplex, tableData } from "constants";

const ConfigModule = () => {
  return (
    <div>
    <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
       <ConfigTable
        columnsData={columnsDataComplex}
        tableData={tableData}
      />
    </div>
  </div>

   
  );
};

export default ConfigModule;
