import PromptTable from "./components/complexTable";
// import ComplexTable from "./components/ComplexTable";

const ReccommendedTripTable = () => {
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <PromptTable />
      </div>
    </div>
  );
};

export default ReccommendedTripTable;
