import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "context/globalContext";
import { ROUTES } from "constants";

const App = () => {
  const navigate = useNavigate();
  const { setUserDetails } = useGlobalContext();
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    // console.log(token, "tok");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserDetails(decodedToken);
        // console.log(decodedToken, "decodedToken");
        if (!decodedToken?.id) {
          navigate("/");
          localStorage.removeItem("jwtToken");
        }
      } catch (error) {
        console.error("Invalid token", error);
      }
    } else {
      navigate("/");
    }
  }, []);

  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="rtl/*" element={<RtlLayout />} />
      <Route path="/" element={<Navigate to={ROUTES.admin} replace />} />
    </Routes>
  );
};

export default App;
