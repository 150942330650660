import React, { useCallback, useEffect, useState } from "react";

import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { useMemo } from "react";
import { format } from "date-fns";
import { useGlobalContext } from "context/globalContext";
import EditAndDelete from "./editAndDelete";
import { getIterinaryListAPI } from "Api/iterinaryListApi";
// import { recommendedTripsURL } from "constants/environment";

const PromptTable = () => {
  const { userDetails, iterinaryList, setIterinaryList } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchIterinaryList, setFetchIterinaryList] = useState(false);

  const getIterinaryList = useCallback(async () => {
    if (!userDetails.id) return;
    const response = await getIterinaryListAPI(
      { id: userDetails.id },
      setIsLoading
    );
    if (response.data) {
      console.log(response.data);
      setIterinaryList(response.data);
      setFetchIterinaryList(false);
    }
  }, [setIterinaryList, userDetails.id]);

  useEffect(() => {
    getIterinaryList();
  }, [getIterinaryList]);

  useEffect(() => {
    if (fetchIterinaryList) getIterinaryList();
  }, [fetchIterinaryList, getIterinaryList]);

  const columnsData = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "tripName",
      },
      {
        Header: "No. of days",
        accessor: "numberOfDaysSelected",
      },
      {
        Header: "Show trip",
        accessor: "featured",
      },
      {
        Header: "Sort order",
        accessor: "topDestination",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const columns = useMemo(() => columnsData, [columnsData]);
  //   console.log(tableData);

  const tableInstance = useTable(
    {
      columns,
      data: iterinaryList,
      initialState: { pageSize: 100 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
      <div className="mt-4 h-full overflow-x-scroll xl:overflow-hidden">
        {isLoading && iterinaryList.length === 0 && <span>...Loading</span>}
        {!isLoading && iterinaryList.length === 0 && (
          <span className="block w-full text-center">
            No Recommended trips found.
          </span>
        )}
        {!!iterinaryList.length && (
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      className="border-b border-gray-200 pr-24 pb-[10px] text-start dark:!border-navy-700"
                    >
                      <p className="text-xs tracking-wide text-gray-600">
                        {column.render("Header")}
                      </p>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = cell.render("Cell");

                      if (cell.column.Header === "Show trip") {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className={`rounded-full text-xl`}>
                              {cell.value === true ? (
                                <MdCheckCircle className="text-green-500" />
                              ) : cell.value === false ? (
                                <MdCancel className="text-red-500" />
                              ) : cell.value === "Error" ? (
                                <MdOutlineError className="text-orange-500" />
                              ) : null}
                            </div>
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value ? "show" : "hide"}
                            </p>
                          </div>
                        );
                      } else if (cell.column.Header === "Value") {
                        data = (
                          <div
                            className="pt-[4px] pb-[8px] sm:text-[14px]"
                            dangerouslySetInnerHTML={{ __html: cell.value }}
                          />
                        );
                      } else if (cell.column.Header === "Created At") {
                        data = format(
                          new Date(cell.value),
                          "yyyy-MM-dd HH:mm:ss"
                        );
                      } else if (cell.column.Header === "Actions") {
                        data = (
                          <EditAndDelete
                            width="w-[68px]"
                            value={cell.value}
                            cell={cell}
                            setFetchIterinaryList={setFetchIterinaryList}
                          />
                        );
                      } else if (cell.column.Header === "Title") {
                        data = (
                          <td
                            className="pt-[14px] pb-[8px] sm:text-[14px]"
                            {...cell.getCellProps()}
                            key={index}
                          >
                            {/* <a
                              href={`${recommendedTripsURL}/shared/${
                                cell.row.original.id
                              }/${cell.row.original.tripName}/${
                                JSON.parse(cell.row.original.metaData)[0]
                                  .selectedChatData.destinationList[0]
                                  ?.description
                              }`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "} */}
                            {data}
                            {/* </a> */}
                          </td>
                        );
                      }

                      return (
                        <td
                          className="pt-[14px] pb-[8px] sm:text-[14px]"
                          {...cell.getCellProps()}
                          key={index}
                          // onClick={() => {
                          //   console.log(
                          //     // cell.row.original.id,
                          //     `${cell.row.original.id}/${
                          //       cell.row.original.tripName
                          //     }/${
                          //       JSON.parse(cell.row.original.metaData)[0]
                          //         .selectedChatData.destinationList[0]
                          //         ?.description
                          //     }`
                          //   );
                          // }}
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Card>
  );
};

export default PromptTable;
