import React, { useState } from "react";
import { BsEye, BsPen, BsTrash } from "react-icons/bs";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import toast from "react-hot-toast";
import ReccommendedFormModal from "./reccommendedFormModal";
import { deleteIterinaryAPI } from "Api/iterinaryListApi";
import { useGlobalContext } from "context/globalContext";
import { recommendedTripsURL } from "constants/environment";

const EditAndDelete = (props) => {
  const { setFetchIterinaryList, cell } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { setIterinaryFormData, userDetails } = useGlobalContext();
  // const { getSystemPromptData } = useGlobalContext();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    const response = await deleteIterinaryAPI({
      id: props.cell.row.original.id,
    });
    if (response) toast.success(`Iterinary deleted successfully`);
    console.log("Item deleted");
    setIsModalOpen(false);
    setFetchIterinaryList(true);
    // getSystemPromptData();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormOpen = () => {
    setIsFormOpen(true);

    setIterinaryFormData({
      ...props.cell.row.original,
    });
    // console.log(props, "props");
  };

  const handleFormClose = () => {
    setIsFormOpen(false);
  };

  const { destinationList = [] } =
    JSON.parse(cell.row.original.metaData)[0]?.selectedChatData || {};

  // console.log(destinationList[0]?.description || "");

  return (
    <div className="flex gap-4">
      <a
        href={`
          ${recommendedTripsURL}/shared/${cell.row.original.id}/${cell.row.original.tripName}/${destinationList[0]?.description}`}
        target="_blank"
        rel="noreferrer"
      >
        <BsEye size={18} className="cursor-pointer fill-amber-900" />
      </a>
      <BsPen
        className="cursor-pointer fill-amber-900"
        onClick={handleFormOpen}
      />
      {userDetails?.role === 1 && (
        <BsTrash
          className="cursor-pointer fill-amber-900"
          onClick={handleDeleteClick}
        />
      )}
      {userDetails?.role === 1 && (
        <DeleteModal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          onDelete={handleDeleteConfirm}
        />
      )}

      <ReccommendedFormModal
        isOpen={isFormOpen}
        onClose={handleFormClose}
        setFetchIterinaryList={setFetchIterinaryList}
      />
    </div>
  );
};

export default EditAndDelete;
