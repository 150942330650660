export const tableData =

[
    {
      "name": "Marketplace",
      "status": "Approved",
      "date": "24.Jan.2021",
      "progress": 30
    },
    {
      "name": "Marketplace",
      "status": "Disable",
      "date": "30.Dec.2021",
      "progress": 30
    },
    {
      "name": "Marketplace",
      "status": "Error",
      "date": "20.May.2021",
      "progress": 30
    },
    {
      "name": "Marketplace",
      "status": "Approved",
      "date": "12.Jul.2021",
      "progress": 30
    }
  ]
  