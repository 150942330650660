import { createContext, useContext, useState, useMemo } from "react";

import axios from "axios";
import { ENDPOINT } from "constants/environment";
const GlobalContext = createContext({});

export const GlobalContextProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({});
  const initialFormState = useMemo(
    () => ({
      key: "",
      value: "",
      status: "",
      comment: "",
      configCategory: "llmCategory",
    }),
    []
  );
  const initialIterinaryFormData = useMemo(
    () => ({
      tripName: "",
      numberOfDaysSelected: 0,
      featured: "",
      topDestination: 0,
    }),
    []
  );
  const initialConfigState = useMemo(
    () => ({
      key: "",
      value: "",
      status: "",
      comment: "",
    }),
    []
  );
  const [formData, setFormData] = useState(initialFormState);
  const [iterinaryFormData, setIterinaryFormData] = useState(
    initialIterinaryFormData
  );
  // const [configData, setConfigData] = useState(initialConfigState);
  const getConfigkeydata = async () => {
    try {
      // const response = await axios.get(`${ENDPOINT}/app/config`);
      const response = await axios.get(`${ENDPOINT}/app/v1/config`);
      if (response.data && response.data.data) {
        setTableData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [tableData, setTableData] = useState([]);
  const [iterinaryList, setIterinaryList] = useState([]);

  const getSystemPromptData = async () => {
    try {
      // const response = await axios.get(`${ENDPOINT}/app/systemprompt`);
      const response = await axios.get(`${ENDPOINT}/app/v1/systemprompt`);
      if (response.data && response.data.data) {
        setTableData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const value = {
    userDetails,
    setUserDetails,
    formData,
    setFormData,
    initialFormState,
    tableData,
    getSystemPromptData,
    getConfigkeydata,
    initialConfigState,
    setIterinaryList,
    iterinaryList,
    iterinaryFormData,
    setIterinaryFormData,
    initialIterinaryFormData,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
