import React, { useState } from "react";
// import "ckeditor5.css";
import { useGlobalContext } from "context/globalContext";
import { updateIterinaryAPI } from "Api/iterinaryListApi";
import toast from "react-hot-toast";

const ReccommendedFormModal = ({ isOpen, onClose, setFetchIterinaryList }) => {
  //   const { getSystemPromptData } = useGlobalContext();
  const {
    setIterinaryFormData,
    iterinaryFormData,
    initialIterinaryFormData,
    userDetails,
  } = useGlobalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIterinaryFormData({
      ...iterinaryFormData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(iterinaryFormData, "iterinaryFormdata");
    // setIsSubmitting(true);
    if (!userDetails?.id) return;
    const {
      id: iterinaryID,
      tripName,
      featured,
      topDestination,
      numberOfDaysSelected,
    } = iterinaryFormData || {};
    if (!iterinaryID) return;
    const response = await updateIterinaryAPI(
      {
        id: iterinaryID,
        tripName: tripName,
        userId: userDetails.id,
        featured,
        topDestination,
        numberOfDaysSelected,
      },
      setIsSubmitting
    );
    if (response) {
      toast.success("Iterinary updated successfully!");
      setFetchIterinaryList(true);
      onClose();
    }
  };

  const handleCancel = () => {
    setIterinaryFormData(initialIterinaryFormData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50">
      <div className="z-50 mx-auto w-full max-w-md rounded-xl bg-white p-6 shadow-xl">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">Title:</label>
            <input
              type="text"
              name="tripName"
              value={iterinaryFormData.tripName}
              onChange={handleChange}
              className="focus:border-transparent w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              No. of days:
            </label>
            <input
              type="number"
              name="numberOfDaysSelected"
              value={iterinaryFormData.numberOfDaysSelected}
              onChange={handleChange}
              className="focus:border-transparent w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Show trip:
            </label>
            <div className="flex items-center">
              <label className="mr-4">
                <input
                  type="radio"
                  name="featured"
                  value={true}
                  checked={iterinaryFormData.featured}
                  onChange={() => {
                    setIterinaryFormData({
                      ...iterinaryFormData,
                      featured: true,
                    });
                  }}
                  className="mr-1"
                />
                Show
              </label>
              <label>
                <input
                  type="radio"
                  name="featured"
                  value={false}
                  checked={!iterinaryFormData.featured}
                  onChange={() => {
                    setIterinaryFormData({
                      ...iterinaryFormData,
                      featured: false,
                    });
                  }}
                  className="mr-1"
                />
                Hide
              </label>
            </div>
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-bold text-gray-700">
              Sort Order:
            </label>
            <input
              type="number"
              name="topDestination"
              value={iterinaryFormData.topDestination}
              onChange={handleChange}
              className="focus:border-transparent w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mt-4 flex justify-between">
            <button
              type="submit"
              className={`rounded-md bg-green-600 py-2 px-4 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 `}
              disabled={isSubmitting}
            >
              {"Update"}
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="rounded-md bg-red-600 py-2 px-4 text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReccommendedFormModal;
