import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import App from "./App";
import { GlobalContextProvider } from "context/globalContext";
import {Toaster} from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
  <GlobalContextProvider>
  <Toaster
        position="bottom-center"
        reverseOrder={false}
      />
    <App />
    </GlobalContextProvider>
  </BrowserRouter>
);
