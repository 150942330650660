import React, { useState } from "react";
import { BsPlus } from "react-icons/bs";
import ConfigForm from "FormModal/ConfigForm";

function ConfigMenu(props) {
  const { transparent,getConfigkeydata } = props;
  const [open, setOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleFormOpen = () => {
    setIsFormOpen(true);
  };
  const handleFormClose = () => {
    setIsFormOpen(false);
  };
  const handleFormSubmit = (formData) => {
    console.log("Form submitted:", formData);
  };

  return (
    <div className="relative">
      <button
        onClick={() => {
          setOpen(!open);
          handleFormOpen();
        }}
        className={`flex items-center text-xl hover:cursor-pointer ${
          transparent
            ? "bg-none text-white hover:bg-none active:bg-none"
            : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
        } linear justify-center rounded-lg font-bold transition duration-200`}
      >
        <BsPlus className="fill-amber-900" />
      </button>
      <ConfigForm
        isOpen={isFormOpen}
        onClose={handleFormClose}
        onSubmit={handleFormSubmit}
        isEditMode={false}
        getConfigkeydata={getConfigkeydata}
      />
    </div>
  );
}

export default ConfigMenu;
