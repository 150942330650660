import axios from "axios";
import { ENDPOINT } from "constants/environment";
import toast from "react-hot-toast";

export const getIterinaryListAPI = async (args, isLoading) => {
  try {
    isLoading(true);
    const response = await axios.get(
      // `${ENDPOINT}/listItineraries?userId=${args.id}`
      `${ENDPOINT}/app/v1/users/itineraries/?userId=${args.id}`
    );
    if (response.data && response.data.data) {
      isLoading(false);
      return response.data;
    }
    throw Error;
  } catch (error) {
    isLoading(false);
    console.error("Error fetching data:", error);
  }
};

export const deleteIterinaryAPI = async (args) => {
  try {
    // const response = await axios.delete(`${ENDPOINT}/itinerary/${args.id}`);
    const response = await axios.delete(
      `${ENDPOINT}/app/v1/itineraries/delete/?id=${args.id}`
    );
    // console.log("Delete successful:", response.data);
    if (response.data) return response.data;
  } catch (error) {
    toast.error("Error deleting the Iterinary!");
    console.error("Error deleting the Iterinary:", error);
  }
};

export const updateIterinaryAPI = async (args, isLoading) => {
  try {
    isLoading(true);
    // const response = await axios.put(`${ENDPOINT}/app/users/itineraries`, args);
    const response = await axios.put(
      `${ENDPOINT}/app/v1/users/itineraries`,
      args
    );
    if (response.data) {
      isLoading(false);
      return response.data;
    }
    isLoading(false);
    throw Error;
  } catch (error) {
    isLoading(false);
    toast.error("Error while updating the Iterinary!");
  }
};
