export const columnsDataDevelopment = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "TECH",
      accessor: "tech",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
  ];
  
  export const columnsDataCheck = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
    {
      Header: "QUANTITY",
      accessor: "quantity",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
  ];
  
  export const columnsDataColumns = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
    {
      Header: "QUANTITY",
      accessor: "quantity",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
  ];
  
  export const columnsDataComplex = [
    {
      Header: "KEY",
      accessor: "key",
    },
    {
      Header: "VALUE",
      accessor: "value",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "COMMENT",
      accessor: "comment",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "ACTIONS",
      accessor: "actions",
    },
  ];
  